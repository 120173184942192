/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaAddConditionedAction', {
        templateUrl: './components/sign/annexa-add-conditioned-action/annexa-add-conditioned-action.html',
        controller: ['Language', '$rootScope', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'SignModals', 'AdminFactory', 'CommonService', '$filter', function (Language, $rootScope, GlobalDataFactory, AnnexaFormlyFactory, SignModals, AdminFactory, CommonService, $filter) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.getAutosignType = function (type) {
            	if(type) {
            		return type[Language.getActiveColumn()];
            	} else {
            		return "";
            	}
            }
			
			vm.getSignatoryType = function (type) {
            	if(type) {
            		return type[Language.getActiveColumn()];
            	} else {
            		if(vm.data.chkSignatoryType) {
                		return "";
                	} else {
                		return $filter('translate')('global.documents.modal.sendToSign.user');
                	}
            	}
            }
			
			vm.showUsers = function(action) {
                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                if(action.signActionType.code != 'THIRDSIGN') {
                	if(action.signatoryType) {
                        if (action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                    	if(vm.data.chkSignatoryType) {
                            return false;
                    	} else {
                    		return true;
                    	}
                    }
                } else {
                	return false;
                }
            };
            
            vm.showLevel = function(action) {
                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                if(action.signActionType.code != 'THIRDSIGN') {
                	if(action.signatoryType) {
                        if (action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                    	if(!vm.data.chkSignatoryType) {
                            return false;
                    	} else {
                    		return true;
                    	}
                    }
                } else {
                	return false;
                }
            };
            
            vm.showThird = function(action) {
                if(action.signActionType.code == 'THIRDSIGN') {
                	return true;
                } else {
                	return false;
                }
            };
            
            vm.getUserName = function (user) {
                if (user && user.user) {
                    var name = user.user.name;
                    if(user.user.surename1){ name = name + " "+user.user.surename1};
                    if(user.user.surename2){name = name + " "+user.user.surename2};
                    return name;
                } else if(user){
                	var name = user.name;
                    if(user.surename1){ name = name + " "+user.surename1};
                    if(user.surename2){name = name + " "+user.surename2};
                    return name;
                } else{
                    return "";
                }
            };
			
            vm.getThirdName = function (action) {
            	if(action.third && action.thirdAddress) {
            		var name = '';
                    if(action.third.corporateName) {
                        name = action.third.corporateName;
                    } else {
                        name = action.third.name + (action.third.surname1Particles ? ' ' + action.third.surname1Particles : '') + ' ' + action.third.surename1;
                    }

                    if(action.third.surename2) {
                        name += (action.third.surname2Particles ? ' ' + action.third.surname2Particles : '') + ' ' + action.third.surename2;
                    }
                    return name + " (" + action.thirdAddress.telematicValue + ")";
            	} else {
            		return "";
            	}
            };
            
            vm.addAction = function (){
            	var modal = angular.copy(SignModals.addCustomCircuit);
            	modal.size = 'modal-md';
            	modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {chkSignatoryType: vm.data.chkSignatoryType, isConditionedAction: true};
                var signActionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signActionType'");
                var signatoryTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signatoryType'");
                var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
				var addPreExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPreExpression'");
				var selectReceiverField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'selectReceiver'");
                if(signatoryTypeField) {
                	_.forEach(AdminFactory.signatoryTypes, function (value) {
           				var type = value;
           				type.label = value[Language.getActiveColumn()];
           				signatoryTypeField.templateOptions.options.push(type);
           			});
                }
                if(signActionTypeField) {
                	signActionTypeField.templateOptions.label = 'global.literals.action';
                }
                if(diliStampTypeField) {
                	_.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                		if(value.certificateType == 'ORGAN') {
                			value.label = value[Language.getActiveColumn()];
               				diliStampTypeField.templateOptions.options.push(value);
                		}
           			});
                }
				if(addPreExpressionField) {
					addPreExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'signProcess', type:{id:'SignProcess',name:'SignProcess'}, id:''});
						params.push({name:'signProcessAction', type:{id:'SignProcessAction',name:'SignProcessAction'}, id:''});
						params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.condition)?modal.annexaFormly.model.modal_body.condition:undefined), params);
					};
				}
				if (selectReceiverField) {
					selectReceiverField.templateOptions.options = vm.data.selectReceiverList;
				}
            	if (selectReceiverField.templateOptions.options.length > 0) {
            		modal.annexaFormly.model.modal_body.receiver = 'related';
            	}
            	var addConditionedAction = function() {
            		var model = this.annexaFormly.model.modal_body;
            		if(model.signActionType.code == 'THIRDSIGN') {
            			model.user = { user: undefined };
            			model.actionLevel = undefined;
            			model.signatoryType = undefined;
            			model.customText = undefined;
            			if (model.receiver == 'others') {
            				model.selectReceiver = undefined;
            			}
            			if (model.selectReceiver) {
            				var receiver = $linq(selectReceiverField.templateOptions.options).firstOrDefault(undefined, "x => x.value == " + model.selectReceiver);
            				model.third = { third: receiver.third };
            				model.third.value = receiver.label;
            				model.thirdAddress = receiver.address;
            			}
            		} else if(model.signActionType.code == 'AUTOSIGN_ORGAN') {
            			model.user = { user: undefined };
            			model.actionLevel = undefined;
            			model.signatoryType = undefined;
            			model.third = { third: undefined };
            			model.thirdAddress = undefined;
            			if(model.diligenceStampType.canChange) {
            				model.customText = model.subject;
            			} else {
            				model.customText = model.subjectAux;
            			}
            			model.receiver = undefined;
            		} else {
            			model.third = { third: undefined };
            			model.thirdAddress = undefined;
            			model.customText = undefined;
            			var $rootScope = angular.element(document.body).injector().get('$rootScope');
            			if (model.chkSignatoryType && model.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                			model.actionLevel = undefined;
                        } else if (model.chkSignatoryType && model.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                			model.user = { user: undefined };
                        }
            			model.receiver = undefined;
            		}
            		if(!vm.data.chkSignatoryType) {
            			var $rootScope = angular.element(document.body).injector().get('$rootScope');
            			model.signatoryType = { id: $rootScope.app.configuration.signatory_type_user.value }
            		}
            		var index = vm.actionsList.push({
                        signActionType: model.signActionType,
                        signatoryType: model.signatoryType,
                        actionLevel: model.actionLevel,
                        user: model.user.user,
                    	receiver: model.receiver,
                    	selectReceiver: model.selectReceiver,
                        third: model.third.third,
                        thirdAddress: model.thirdAddress,
                        diligenceStampType: model.diligenceStampType,
                        subject: model.subject,
                        subjectAux: model.subjectAux,
                        spel: model.condition,
                        customText: model.customText
                    }) -1;
            		this.close();
            	};
            	AnnexaFormlyFactory.showModal("modalAddConditionedAction", modal, addConditionedAction, false);
            }
            
            vm.editAction = function ($index){
            	var modal = angular.copy(SignModals.addCustomCircuit);
            	modal.size = 'modal-md';
                modal.title = 'global.documents.modal.sendToSign.editUserThird';
            	modal.annexaFormly.model = {};
                var signActionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signActionType'");
                var signatoryTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'signatoryType'");
                var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
				var addPreExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPreExpression'");
				var selectReceiverField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'selectReceiver'");
                if(signatoryTypeField) {
                	_.forEach(AdminFactory.signatoryTypes, function (value) {
           				value.label = value[Language.getActiveColumn()];
           				signatoryTypeField.templateOptions.options.push(value);
           			});
                }
                if(signActionTypeField) {
                	signActionTypeField.templateOptions.label = 'global.literals.action';
                }
                if(diliStampTypeField) {
                    _.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                            if(value.certificateType == 'ORGAN') {
                            	value.label = value[Language.getActiveColumn()];
                                   diliStampTypeField.templateOptions.options.push(value);
                                   if(vm.actionsList[$index].diligenceStampType 
                                		   && vm.actionsList[$index].diligenceStampType.id == value.id) {
                                	   vm.actionsList[$index].diligenceStampType = value;
                                   }
                            }
                       });
                }
				if(addPreExpressionField) {
					addPreExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'signProcess', type:{id:'SignProcess',name:'SignProcess'}, id:''});
						params.push({name:'signProcessAction', type:{id:'SignProcessAction',name:'SignProcessAction'}, id:''});
						params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.condition)?modal.annexaFormly.model.modal_body.condition:undefined), params);
					};
				}
				if (selectReceiverField) {
					selectReceiverField.templateOptions.options = vm.data.selectReceiverList;
				}
                var subject = undefined;
                if(vm.actionsList[$index].diligenceStampType) {
                	subject = vm.actionsList[$index].customText;
                }
                modal.annexaFormly.model.modal_body = {
                		signActionType: vm.actionsList[$index].signActionType,
                		signatoryType: vm.actionsList[$index].signatoryType,
                		actionLevel: vm.actionsList[$index].actionLevel,
                		receiver: vm.actionsList[$index].receiver,
                    	selectReceiver: vm.actionsList[$index].selectReceiver,
                		thirdAddress: vm.actionsList[$index].thirdAddress,
						diligenceStampType: vm.actionsList[$index].diligenceStampType,
						subject: subject,
						subjectAux: subject,
						condition: vm.actionsList[$index].spel
                }
                if(vm.actionsList[$index].user && !vm.actionsList[$index].user.user) {
                	var user = angular.copy(vm.actionsList[$index].user);
                	modal.annexaFormly.model.modal_body.user = {
                    		user: {
                    			completeName: user.completeName,
                    			id: user.id,
                    			identifier: user.identifier,
                    			name: user.name,
                    			surename1: user.surename1,
                    			surename2: user.surename2
                    		},
                    		id: user.id,
                    		value: user.completeName
                    };
                }
                if(vm.actionsList[$index].third && !vm.actionsList[$index].third.third) {
                	var third = angular.copy(vm.actionsList[$index].third);
                	var name = '';
                    if(third.corporateName) {
                        name = third.corporateName;
                    } else {
                        name = third.name + (third.surname1Particles ? ' ' + third.surname1Particles : '') + ' ' + third.surename1;
                    }

                    if(third.surename2) {
                        name += (third.surname2Particles ? ' ' + third.surname2Particles : '') + ' ' + third.surename2;
                    }
                	modal.annexaFormly.model.modal_body.third = {
                		third: {
                			addresses: third.addresses,
                			completeName: third.completeName,
                			corporateName: third.corporateName,
                			id: third.id,
                			name: third.name,
                			surname1Particles: third.surname1Particles,
                			surename1: third.surename1,
                			surname2Particles: third.surname2Particles,
                			surename2: third.surename2
                		},
                		id: third.id,
                		value: name
                    };
                }
                if (selectReceiverField.templateOptions.options.length > 0 && !modal.annexaFormly.model.modal_body.receiver) {
            		modal.annexaFormly.model.modal_body.receiver = 'related';
            	}
				if (modal.annexaFormly.model.modal_body.selectReceiver) {
					modal.annexaFormly.model.modal_body.third = undefined;
					modal.annexaFormly.model.modal_body.thirdAddress = undefined;
				}
                modal.annexaFormly.model.modal_body.chkSignatoryType = vm.data.chkSignatoryType;
                modal.annexaFormly.model.modal_body.isConditionedAction = true;
            	var editConditionedAction = function() {
            		var model = this.annexaFormly.model.modal_body;
            		if(model.signActionType.code == 'THIRDSIGN') {
            			model.user = { user: undefined };
            			model.actionLevel = undefined;
            			model.signatoryType = undefined;
            			if (model.receiver == 'others') {
            				model.selectReceiver = undefined;
            			}
            			if (model.selectReceiver) {
            				var receiver = $linq(selectReceiverField.templateOptions.options).firstOrDefault(undefined, "x => x.value == " + model.selectReceiver);
            				model.third = { third: receiver.third };
            				model.third.value = receiver.label;
            				model.thirdAddress = receiver.address;
            			}
            		} else if(model.signActionType.code == 'AUTOSIGN_ORGAN') {
            			model.user = { user: undefined };
            			model.actionLevel = undefined;
            			model.signatoryType = undefined;
            			model.third = { third: undefined };
            			model.thirdAddress = undefined;
            			if(model.diligenceStampType.canChange) {
            				vm.actionsList[$index].customText = model.subject;
            			} else {
            				vm.actionsList[$index].customText = model.subjectAux;
            			}
            			model.receiver = undefined;
            		} else {
            			model.third = { third: undefined };
            			model.thirdAddress = undefined;
            			var $rootScope = angular.element(document.body).injector().get('$rootScope');
            			if (model.chkSignatoryType && model.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                			model.actionLevel = undefined;
                        } else if (model.chkSignatoryType && model.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                			model.user = { user: undefined };
                        }
            			model.receiver = undefined;
            		}
            		if(!vm.data.chkSignatoryType) {
            			var $rootScope = angular.element(document.body).injector().get('$rootScope');
            			model.signatoryType = { id: $rootScope.app.configuration.signatory_type_user.value }
            		}
                    vm.actionsList[$index].signActionType = model.signActionType;
                    vm.actionsList[$index].signatoryType = model.signatoryType;
                    vm.actionsList[$index].actionLevel = model.actionLevel;
                    vm.actionsList[$index].user = model.user.user;
                    vm.actionsList[$index].receiver = model.receiver;
                    vm.actionsList[$index].selectReceiver = model.selectReceiver;
                    vm.actionsList[$index].third = model.third.third;
                    vm.actionsList[$index].thirdAddress = model.thirdAddress;
                    vm.actionsList[$index].diligenceStampType = model.diligenceStampType;
                    vm.actionsList[$index].subject = model.subject;
                    vm.actionsList[$index].subjectAux = model.subjectAux;
                    vm.actionsList[$index].spel = model.condition;
            		this.close();
            	};
            	AnnexaFormlyFactory.showModal("modalEditConditionedAction", modal, editConditionedAction, false);
            }
            
            vm.removeAction = function(index) {
            	if(vm.data && vm.data.isEdit){
            		vm.data.deleteActionsList.push(vm.actionsList[index]);
            		vm.actionsList.splice(index, 1);
	            } else {
	                vm.actionsList.splice(index, 1);
	            }
            };

            vm.cancelAction = function(index, rowform){
                if(vm.actionsList[index]&& !vm.actionsList[index].id){
                    vm.actionsList.splice(index, 1);
                }
                rowform.$cancel();
            }
            
            vm.saveAction = function(data, id) {
                angular.extend(data, {id: id});
                if(data.actionOrder != null){
                    if(data.actionOrder !=  vm.actionsList[id].actionOrder) {
                        var existOrder = false;
                        var orderActualNull = (vm.actionsList[id].actionOrder == null || vm.actionsList[id].actionOrder == undefined);
                        for(var i = 0; i < vm.actionsList.length; i++){
                            if(vm.actionsList[i].actionOrder == data.actionOrder){
                                existOrder = true;
                                break;
                            }
                        }
                        if(existOrder){
                        	var actualOrder = vm.actionsList[id].actionOrder;
                        	var newOrder = data.actionOrder;
                            for (var i = 0; i <vm.actionsList.length; i++) {
                            	
                            	var userCircuit = vm.actionsList[i];
                            	if(!orderActualNull) {
                            		if (userCircuit.idTable != id) {
                            			if(actualOrder < newOrder) {
                            				if(actualOrder < userCircuit.actionOrder && newOrder >= userCircuit.actionOrder) {
                                				userCircuit.actionOrder = userCircuit.actionOrder - 1;
                                			}
                            			} else if(actualOrder > newOrder) {
                            				if(actualOrder > userCircuit.actionOrder && newOrder <= userCircuit.actionOrder) {
                                				userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                			}
                            			}
                            		} 
                            	}else {
                            		if (userCircuit.idTable != id) {
                                        if(userCircuit.actionOrder > newOrder){
                                            userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                        }else if(userCircuit.actionOrder == newOrder){
                                            if (newOrder == vm.actionsList.length) {
                                                userCircuit.actionOrder = userCircuit.actionOrder - 1;
                                            } else {
                                                userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                            }
                                        }
                                    }
                            	}
                            }
                        }
                    }
                }
            };
					
            this.$onInit = function () {
            	vm.actionsList = [];
				if(vm.data && vm.data.actionsList){
					vm.actionsList = vm.data.actionsList;
				}	
            }
        }],
        bindings: {
            data: '='
        }
    })
	
	